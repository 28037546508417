import { Capacitor } from '@capacitor/core';
import { useEffect, useState } from 'react';

import { useSafeArea } from 'hooks';

export interface DeviceInfo {
  isAndroid: boolean;
  bottom: number;
  isIos: boolean;
  top: number;
}

export const initialDeviceInfo: DeviceInfo = {
  isAndroid: false,
  bottom: 0,
  isIos: false,
  top: 0,
};

const useCapacitorDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(initialDeviceInfo);
  const { bottom, top } = useSafeArea();

  useEffect(() => {
    const isAndroid = Capacitor.getPlatform() === 'android';
    const isIos = Capacitor.getPlatform() === 'ios';
    setDeviceInfo({
      isAndroid,
      isIos,
      bottom: isIos ? bottom / 2 : bottom,
      top: isIos ? top / 2 : top,
    });
  }, [bottom, top]);

  return deviceInfo;
};

export default useCapacitorDeviceInfo;
