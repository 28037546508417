import { createRoute } from 'utils/router.utils';

import { Routes } from 'constants/routes.constants';

import { Modules } from 'components/@navigations/config';

export default createRoute({
  path: `${Routes.Tasks}/*`,
  lazy: () => import('./Tasks'),
  moduleId: Modules.Tasks,
  overrideEnableZoomScaling: true,
  containerSx: {
    width: '100%',
    margin: 0,
    padding: '0!important',
    maxWidth: 'initial !important',
    display: 'flex',
    justifyContent: 'center',
  },
});
