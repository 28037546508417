import { Box, Button, Grid2 as Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import config from 'config';

import { useMedia } from 'hooks/useMedia';
import { useDeviceInfo } from 'services/capacitor/CapacitorProvider';

import { GooeyTypography } from 'components/@common';
import Icon from 'components/@icons';

interface Props {
  title: React.ReactNode;
  content?: React.ReactNode;
  banner?: React.ReactNode;
  nextButtonText?: React.ReactNode;
  onBack?: () => void;
  onNext?: () => void;
}

const OnboardingStep = ({ title, content, banner, nextButtonText, onBack, onNext }: Props) => {
  const { isDesktop } = useMedia();
  const logo = config.ASSETS.logo.onboarding;

  const deviceInfo = useDeviceInfo();
  const { isAndroid, bottom, isIos } = deviceInfo || {};

  if (!isDesktop)
    return (
      <Box
        position="fixed"
        top={0}
        bottom={isAndroid ? `${bottom}px` : 0}
        width="100%"
        display="flex"
        flexDirection="column"
        bgcolor={(theme) => theme.palette.brand.main[100]}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={logo} alt="logo" height="190px" width="190px" />
        </Box>
        <Box
          bgcolor={(theme) => theme.palette.neutral01[100]}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
          pt={3}
          px={2}
          overflow="scroll"
        >
          <Box>
            <GooeyTypography
              variant="h3"
              backgroundColor="brand.title.onboarding.100"
              color="brand.title.onboarding.invert"
            >
              {title}
            </GooeyTypography>
            <Box mt={2}>{content}</Box>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection="column"
            mt={4}
            gap={1}
            mb={isIos ? 2 : 0}
          >
            {onNext && nextButtonText && (
              <Button fullWidth variant="main" size="large" onClick={onNext}>
                {nextButtonText}
              </Button>
            )}

            {onBack && (
              <Button
                fullWidth
                variant="text"
                size="large"
                startIcon={<Icon name="IcoArrowBack" size={16} />}
                onClick={onBack}
              >
                <FormattedMessage id="core_general_navigate_back" />
              </Button>
            )}
          </Box>
        </Box>

        {!!banner && (
          <Box
            position="fixed"
            bottom={0}
            width="100%"
            sx={(theme) => ({ backgroundColor: theme.palette.brand.action.contrast[100] })}
          >
            {banner}
          </Box>
        )}
      </Box>
    );

  return (
    <>
      <Grid container minHeight="100vh">
        <Grid size={{ xs: 7 }} pl={18} pr={3} pt={5} pb={2} mb={banner ? 9 : 0}>
          {onBack ? (
            <Box mb={12}>
              <Button
                variant="text"
                size="large"
                startIcon={<Icon name="IcoArrowBack" size={16} />}
                onClick={onBack}
              >
                <FormattedMessage id="core_general_navigate_back" />
              </Button>
            </Box>
          ) : (
            <Box mb={12} height={48} />
          )}

          <Box maxWidth={550}>
            <GooeyTypography
              variant="h3"
              backgroundColor="brand.title.onboarding.100"
              color="brand.title.onboarding.invert"
            >
              {title}
            </GooeyTypography>
            <Box my={3}>{content}</Box>
          </Box>
          {onNext && nextButtonText && (
            <Button variant="main" size="large" onClick={onNext}>
              {nextButtonText}
            </Button>
          )}
        </Grid>
        <Grid
          size={{ xs: 5 }}
          position="fixed"
          right={0}
          height="100%"
          width="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="brand.main.100"
          sx={(theme) => ({ color: theme.palette.brand.main[100] })}
        >
          <img
            src={logo}
            alt="logo"
            style={{ maxWidth: '100%', objectFit: 'contain' }}
            height="400px"
            width="400px"
          />
        </Grid>
      </Grid>

      {banner && (
        <Box
          position="fixed"
          bottom={0}
          width="100%"
          sx={(theme) => ({ backgroundColor: theme.palette.brand.action.contrast[100] })}
        >
          {banner}
        </Box>
      )}
    </>
  );
};

export default OnboardingStep;
