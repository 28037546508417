import { Capacitor } from '@capacitor/core';
import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area';
import { useEffect, useState } from 'react';

interface SafeAreaValues {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export const useSafeArea = (): SafeAreaValues => {
  const [safeArea, setSafeArea] = useState<SafeAreaValues>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  useEffect(() => {
    const fetchSafeArea = async () => {
      if (Capacitor.isNativePlatform()) {
        try {
          const insets: SafeAreaInsets = await SafeArea.getSafeAreaInsets();
          setSafeArea({
            top: insets.insets.top,
            right: insets.insets.right,
            bottom: insets.insets.bottom,
            left: insets.insets.left,
          });
        } catch (error) {
          console.error('Error fetching safe area insets:', error);
        }
      }
    };

    fetchSafeArea();
  }, []);

  return safeArea;
};
